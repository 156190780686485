import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, Router, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AuthService } from '../services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  constructor( private afAuth: AngularFireAuth, private router: Router, private authService: AuthService, public navCtrl: NavController)
    {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    if ( this.authService.isLoggedIn() ) {
      
        return true;
    }
    this.router.navigateByUrl('/login');
    return false;

    //   let userAuthenticated = false;
    //   this.afAuth.authState.subscribe(
    //     (user) =>{
    //       console.log( 'GUARD authstate' , user)
    //       if (user) {
    //         userAuthenticated = true;
            
    //         // return true

    //       }
    //       else{
    //         console.log( 'GUARD authstate ERROR' , user)
    //         this.router.navigate(['login']);
    //         userAuthenticated = false;
    //         // return false
    //       }
    //     }
    //   )

    // return userAuthenticated;
  }
  
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
