import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ModalController } from '@ionic/angular';

import { MainMenuComponent } from '../menus/main-menu/main-menu.component'
import { EditUserComponent } from './edit-user/edit-user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.page.html',
  styleUrls: ['./users.page.scss'],
})
export class UsersPage implements OnInit {
  users
  constructor(private firestore: AngularFirestore, private modalController: ModalController) { 

  }

  ngOnInit() {
    this.getUsersList()
  }

  getUsersList(){
    this.firestore.collection('users').snapshotChanges()
    .subscribe(
      (res)=>{
        console.log('Users page] users  ', res)
        this.users = []
        res.forEach(
          r=>{
            this.users.push({key: r.payload.doc.id, data: r.payload.doc.data()})
          }
        )
      }
    )
  }

  async editUser(user){
    const modal = await this.modalController.create({
      component: EditUserComponent,
      componentProps: {
        'user': user
      }
    });
      
    await modal.present();
      modal.onWillDismiss().then(
        (modalData) => {
          console.log('modalData', modalData.data)
          if (modalData){
            let user = modalData.data
          
            this.firestore.doc('users/' + user.key).set(user.data)
            .then(
              (res)=>{ alert('Data saved!')}
            )
            .catch(
              (err)=>{ 
                alert('Data not saved!')
                console.log('[Edit User] ERROR ', err)
              }
            )
          }
        }
      )
  }

  changeActivationStatus(user, status){
    if (confirm('Are you sure you want to do this?')){
      console.log('Activate user ', user)
      user.data.active = status
      this.firestore.doc('users/' + user.key).set(user.data)
      .then(
        (res)=>{ alert('Data saved!')}
      )
      .catch(
        (err)=>{ 
          alert('Data not saved!')
          console.log('[Users] [changeActivationStatus] ERROR ', err)
        })
    }
  }

}
