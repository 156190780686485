import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { QuillModule } from 'ngx-quill'

@Component({
  selector: 'app-edit-homesection',
  templateUrl: './edit-homesection.page.html',
  styleUrls: ['./edit-homesection.page.scss'],
})
export class EditHomesectionPage implements OnInit {
  resource
  datePickerConfig={
    
  }
  
  constructor(private modalController: ModalController, private firestore: AngularFirestore) { 
    // this.getCountries()
  }

  ngOnInit() {
    
  }

  // getCountries(){
  //   this.firestore.collection('countries').snapshotChanges()
  //   .subscribe(
  //     (res)=>{
  //       console.log('[Add news] [GetCountries] Countries ', res)
  //       res.sort((a, b) => a.payload.doc.id.localeCompare(b.payload.doc.id))

  //       this.countries = []
  //       res.forEach(
  //         r=>{
  //           this.countries.push(r.payload.doc.id)
  //         }
  //       )
  //     }
  //   )
  // }

  async returnData(){
    this.modalController.dismiss(this.resource)
  }
}
