import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  @Input() user;

  signupForm: FormGroup;

  constructor(formBuilder: FormBuilder, public modalController: ModalController ) {
    this.signupForm = formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      // selectedCountries: ['', Validators.compose([Validators.required, Validators.minLength(1)])],
      // email: ['', Validators.compose([Validators.required, Validators.email, Validators.minLength(5)])],
      phone: [null],
      companyName: [null],
      companyRegNo:[null],
      companyVatNo: [null],
      companyBankAccount:[null],
      companyBankName:[null]
    });
   }

  ngOnInit() {}

  dismissModal(){
    this.modalController.dismiss(this.user)
  }

}
