import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { IonReorderGroup } from '@ionic/angular';
import {map, take} from 'rxjs/operators'
import { Pipe, PipeTransform } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { TextareaAutosizeDirective } from '../directives/textarea-autosize.directive';

import { AddHomesectionPage } from './add-homesection/add-homesection.page'
import { EditHomesectionPage } from './edit-homesection/edit-homesection.page'
import { MainMenuComponent } from '../menus/main-menu/main-menu.component'
import { AuthService } from '../services/auth.service'

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {

  resources
  latestUpdates
  videos = []
  selectedCountry
  currentUser
  currentUserAditionalData

  constructor(private firestore: AngularFirestore, private _firebaseAuth: AngularFireAuth, public modalController: ModalController, private authService: AuthService) {
    this.getUserInfo()
    
  }
  
  getUserInfo(){
    this._firebaseAuth.authState.subscribe(
      (res) => {
          if (res) {
            this.currentUser = res;
            this.firestore.doc('users/' + this.currentUser.uid).valueChanges().subscribe(
              (r)=>{
                this.currentUserAditionalData = r
                console.log('[HomepageComponent] User details', this.currentUserAditionalData);
              }
            )
          }
          else {
            this.currentUser = null;
          }
        }
    )
  }


  ngOnInit() {
    this.getLatestUpdates() 
  }

  getLatestUpdates(){
    this.firestore.collection('homepage/latestUpdates/updates').snapshotChanges()
    .subscribe(
      (res)=>{
        this.latestUpdates = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc.id
            art['info']['date'] = art['info']['date_time'].toDate()
            this.latestUpdates.push(art)
          }
        )
        this.latestUpdates
        .sort(function (a, b) {
          if (a['info']['date'] > b['info']['date']) {
              return -1;
          }
          if (b['info']['date'] > a['info']['date']) {
              return 1;
          }
          return 0;
        })
        console.log('resources ', this.resources)
      }
    )
  }

  async addUpdate(){
    const modal = await this.modalController.create({
      component: AddHomesectionPage
      });
      
    await modal.present();
    modal.onWillDismiss().then(
      (modalData) => {
        if (modalData){
          let newUpdate = modalData.data
          console.log('this.currentUser', this.currentUser)
          console.log('this.currentUserAditionalData', this.currentUserAditionalData)
          newUpdate['user'] = {userId: this.currentUser.uid, displayName: this.currentUserAditionalData.name , email: this.currentUser.email }
          newUpdate['date_time'] = new Date()
          console.log('New resources', newUpdate)
          this.firestore.collection('homepage').doc('latestUpdates').collection('updates').add(newUpdate)
          .then(
            res => console.log('Update added sucessuffly ', res)
          )
          .catch(
          err => console.log('Update NOT added ', err)
          )
        }
      }
    )
  }

  async editUpdate(update){
   
    console.log('editUpdate ', update)
    const modalEdit = await this.modalController.create({
      component: EditHomesectionPage,
      componentProps: { resource: update.info}
      });
      
    await modalEdit.present();
    modalEdit.onWillDismiss().then(
      (modalData) => {
        if (modalData){
          let newUpdate = modalData.data
          newUpdate['user'] = {userId: this.currentUser.uid, displayName: this.currentUserAditionalData.name , email: this.currentUser.email }
          if(newUpdate['new_date']){
            newUpdate['date_time'] = newUpdate['new_date'].toDate()
            delete newUpdate.new_date
            delete newUpdate.date
          }
          console.log('New UPDATE info', newUpdate)
          this.firestore.collection('homepage').doc('latestUpdates').collection('updates').doc(update.id).set(newUpdate)
          .then(
            res => console.log('Update Edited sucessuffly ', res)
          )
          .catch(
          err => console.log('Update NOT Edited ', err)
          )
        }
      }
    )
  }

  deleteResource(id){
    if (confirm('Are you sure you want to delete this?') ){
      this.firestore.doc('homepage/latestUpdates/updates/' + id  ).delete()
    }
  }



  reOrderArticles($event){
    // console.log('Reorder', this.fields)
    // console.log('ReOrder: ', $event)

  }

}
