import { Component, AfterViewInit, Input, ViewChild,  ElementRef } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Component({
  selector: "attachments",
  templateUrl: "attachments.html"
})
export class AttachmentsComponent {
  @Input("task") task;
  taskId
  currentUserAdditionalData
  files: any = [];
 
  constructor (private authService: AuthService, public afStorage: AngularFireStorage, private firestoreDb: AngularFirestore){
    this.currentUserAdditionalData = authService.userDetails;
    
    
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.taskId = this.task['id']
    console.log('[Attachments] task', this.task)
  }

  ngOnChanges(changes): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.taskId = this.task['id']
    this.files = []
  }

  filesReadyToUpload(event) {
    console.log('[filesReadyToUpload]', event)
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element)
    }  
  }

  deleteAttachment(index) {
    this.files.splice(index, 1)
  }
 

  uploadAllFiles(files:any):void{
    console.log('[Attachments][uploadFiles] files', this.files)
    this.files.forEach(file => {
      const tDate = new Date()
      const filePrefix = '' + tDate.getFullYear() + tDate.getMonth() + tDate.getDay() + tDate.getHours() + tDate.getMinutes() + tDate.getSeconds() + '_'
      const filePath = this.currentUserAdditionalData.teamId + '/' + this.taskId + '/' + filePrefix + file.name;
      const fileRef = this.afStorage.ref(filePath);
      const task = this.afStorage.upload(filePath, file);
  
      // observe percentage changes
      // this.uploadPercent = task.percentageChanges();
  
      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(() => {
            console.log('fileRef', fileRef)
            let imageObservable = fileRef.getDownloadURL()
            imageObservable.subscribe(
              (r)=>{
                if (r){
                  
                  this.saveAttachmentToDatabase(r, file.name, file.type);
                }
              }
            )
          } )
        )
      .subscribe()
    });
   

  }

  saveAttachmentToDatabase(downloadUrl, easyName, fileType){
    console.log('[Attachments component] [saveAttachmentToDatabase] this.taskId ', this.taskId)
    console.log('[Attachments component] [saveAttachmentToDatabase] downloadUrl ', downloadUrl)
    let promise = new Promise((resolve, reject) => {
      const attach = {'easyName': easyName, 'downloadUrl': downloadUrl, 'fileType': fileType}
      this.firestoreDb.collection('timetrack_'+ this.currentUserAdditionalData.teamId).doc(this.taskId).collection('attachments').add(attach)
        .then(
          (r) => {
            resolve('OK - Saved attachment to database: ' + r)
          }
        )
        .catch(
          (err)=>{ reject('Error saving attachment to database ' + err)
        }
      )

    });
    return promise;
  }

}
