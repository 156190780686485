import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { IonReorderGroup } from '@ionic/angular';
import {map, take} from 'rxjs/operators'
import { Pipe, PipeTransform } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { TextareaAutosizeDirective } from '../directives/textarea-autosize.directive';

import { AddResourcesPage } from './add-resources/add-resources.page'
import { EditResourcePage } from './edit-resource/edit-resource.page'
import { MainMenuComponent } from '../menus/main-menu/main-menu.component'
import { AuthService } from '../services/auth.service'

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit {

  resources
  articles
  webinars
  trainings
  videos = []
  selectedCountry
  currentUser
  currentUserAditionalData

  constructor(private firestore: AngularFirestore, private _firebaseAuth: AngularFireAuth, public modalController: ModalController, private authService: AuthService) {
    this.getUserInfo()
    
  }
  
  getUserInfo(){
    this._firebaseAuth.authState.subscribe(
      (res) => {
          if (res) {
            this.currentUser = res;
            console.log('[Auth Service] User details', this.currentUserAditionalData);
            this.firestore.doc('users/' + this.currentUser.uid).valueChanges().subscribe(
              (r)=>{
                this.currentUserAditionalData = r
              }
            )
          }
          else {
            this.currentUser = null;
          }
        }
    )
  }


  ngOnInit() {
    this.getArticles() 
    this.getVideos()
    this.getWebinars()
    this.getTrainings()
  }

  getArticles(){
    this.firestore.collection('resources/article/articles').snapshotChanges()
    .subscribe(
      (res)=>{
        this.articles = []
        res.forEach(
          r=>{
            let art = {}
            art['type'] = 'article-link'
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc.id
            art['data'] = art['info']['date_time'].toDate()
            this.articles.push(art)
          }
        )
        this.getFullArticles()
        this.articles
        .sort(function (a, b) {
          if (a['data'] > b['data']) {
              return -1;
          }
          if (b['data'] > a['data']) {
              return 1;
          }
          return 0;
        })
        console.log('resources ', this.resources)
      }
    )
  }

  getFullArticles(){
    this.firestore.collection('resources/full-article/full-articles').snapshotChanges()
    .subscribe(
      (res)=>{
        res.forEach(
          r=>{
            let art = {}
            art['type'] = 'full-article'
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc.id
            art['data'] = art['info']['date_time'].toDate()
            this.articles.push(art)
          }
        )
        this.articles
        .sort(function (a, b) {
          if (a['data'] > b['data']) {
              return -1;
          }
          if (b['data'] > a['data']) {
              return 1;
          }
          return 0;
        })
        console.log('resources ', this.resources)
      }
    )
  }

  getVideos(){
    this.firestore.collection('resources/video/videos').snapshotChanges()
    .subscribe(
      (res)=>{
        this.videos = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc.id
            art['data'] = art['info']['date_time'].toDate()
            this.videos.push(art)
          }
        )
        this.videos
        .sort(function (a, b) {
          if (a['data'] > b['data']) {
              return -1;
          }
          if (b['data'] > a['data']) {
              return 1;
          }
          return 0;
        })
        console.log('videos ', this.videos)
      }
    )
  }

  getWebinars(){
    this.firestore.collection('resources/webinar/webinars').snapshotChanges()
    .subscribe(
      (res)=>{
        this.webinars = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc.id
            art['data'] = art['info']['date_time'].toDate()
            this.webinars.push(art)
          }
        )
        this.webinars
        .sort(function (a, b) {
          if (a['data'] > b['data']) {
              return -1;
          }
          if (b['data'] > a['data']) {
              return 1;
          }
          return 0;
        })
        console.log('webinars ', this.webinars)
      }
    )
  }

  getTrainings(){
    this.firestore.collection('resources/training/trainings').snapshotChanges()
    .subscribe(
      (res)=>{
        this.trainings = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc.id
            art['data'] = art['info']['date_time'].toDate()
            this.trainings.push(art)
          }
        )
        this.trainings
        .sort(function (a, b) {
          if (a['data'] > b['data']) {
              return -1;
          }
          if (b['data'] > a['data']) {
              return 1;
          }
          return 0;
        })
        console.log('trainings ', this.trainings)
      }
    )
  }


  async addResource(){
    const modal = await this.modalController.create({
      component: AddResourcesPage
      });
      
    await modal.present();
    modal.onWillDismiss().then(
      (modalData) => {
        if (modalData){
          let newResource = modalData.data
          console.log('this.currentUser', this.currentUser)
          console.log('this.currentUserAditionalData', this.currentUserAditionalData)
          // newResource['user'] = {userId: this.currentUser.uid, displayName: this.currentUser.displayName , email: this.currentUser.email }
          newResource['date_time'] = new Date()
          console.log('New resources', newResource)
          this.firestore.collection('resources').doc(modalData.data['type']).collection(modalData.data['type']+'s').add(modalData.data)
          .then(
            res => console.log('Resource added sucessuffly ', res)
          )
          .catch(
          err => console.log('Resource NOT added ', err)
          )
        }
      }
    )
  }

  async editResource(resource){
   
    console.log('edit Resource ', resource)
    const modalEdit = await this.modalController.create({
      component: EditResourcePage,
      componentProps: { resource: resource.info}
      });
      
    await modalEdit.present();
    modalEdit.onWillDismiss().then(
      (modalData) => {
        if (modalData){
          let newResource = modalData.data
          console.log('[Edit resource] newResource', newResource)
          if(newResource['new_date']){
            newResource['date_time'] = newResource['new_date'].toDate()
            delete newResource.new_date
            delete newResource.date
          }
          console.log('[Edit resource] New UPDATE info', newResource)
          this.firestore.collection('resources').doc(modalData.data['type']).collection(modalData.data['type']+'s').doc(resource.id).set(newResource)
          .then(
            res => console.log('News Edited sucessuffly ', res)
          )
          .catch(
          err => console.log('News NOT Edited ', err)
          )
        }
      }
    )
  }

  deleteResource(resource){
    if (confirm('Are you sure you want to delete this?') ){
      console.log(' DELETE RESOURCE', resource)
      this.firestore.collection('resources').doc(resource.info['type']).collection(resource.info['type']+'s').doc(resource.id).delete()
      // this.firestore.doc('news/' + id  ).delete()
    }
  }



  reOrderArticles($event){
    // console.log('Reorder', this.fields)
    // console.log('ReOrder: ', $event)

  }

}
