import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { environment } from '../environments/environment';

import { ArticlesComponent } from './articles/articles.component';
import { LogsComponent } from './logs/logs.component';
import { UsersPage } from './users/users.page';
import { AddFieldPageModule } from './articles/add-field/add-field.module';
import { AddSectionPageModule } from './articles/add-section/add-section.module';
import { AddCountryPageModule } from './articles/add-country/add-country.module';
import { ShowFieldHistoryPageModule } from './articles/show-field-history/show-field-history.module';
import { MainMenuComponent } from './menus/main-menu/main-menu.component'
import { NewsComponent } from './news/news.component';
import { AddNewsPage } from './news/add-news/add-news.page';
import { EditNewsPage } from './news/edit-news/edit-news.page';
import { EditUserComponent } from './users/edit-user/edit-user.component';
import { ResourcesComponent } from './resources/resources.component';
import { HomepageComponent } from './homepage/homepage.component';
import { AddResourcesPage } from './resources/add-resources/add-resources.page';
import { EditResourcePage } from './resources/edit-resource/edit-resource.page'

import { AddHomesectionPage } from './homepage/add-homesection/add-homesection.page';
import { EditHomesectionPage } from './homepage/edit-homesection/edit-homesection.page'
import { AttachmentsComponent } from './attachments/attachments'

import { AuthService } from './services/auth.service';

import { QuillModule } from 'ngx-quill'
import { DpDatePickerModule } from 'ng2-date-picker';

@NgModule({
  declarations: [AppComponent,
    ArticlesComponent,
    MainMenuComponent,
    UsersPage,
    NewsComponent,
    AddNewsPage,
    EditNewsPage,
    EditUserComponent,
    ResourcesComponent,
    AttachmentsComponent,
    AddResourcesPage,
    EditResourcePage,
    HomepageComponent,
    AddHomesectionPage,
    EditHomesectionPage,
    LogsComponent
  ],
  entryComponents: [
    AddNewsPage,
    EditNewsPage,
    EditUserComponent,
    AddResourcesPage,
    EditResourcePage,
    AddHomesectionPage,
    EditHomesectionPage
  ],
  imports: [
    FormsModule, ReactiveFormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'angular-auth-firebase'),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AddFieldPageModule,
    AddSectionPageModule,
    AddCountryPageModule,
    ShowFieldHistoryPageModule,
    QuillModule.forRoot(),
    AngularMultiSelectModule,
    DpDatePickerModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
