import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Articles',
      url: '/articles',
      icon: 'newspaper'
    },
    {
      title: 'Users',
      url: '/users',
      icon: 'people-circle'
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor( private authService: AuthService, private afAuth: AngularFireAuth, public router: Router, private firestore: AngularFirestore,) { }

  ngOnInit() {
     const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  logOut() {
    console.log('Logout')
    this.afAuth.signOut()
    .then(
      r => { 
        console.log('Logged out', r)
        this.router.navigate(['login']);
      }
    )
    .catch(
      e =>  console.log('ERROR Logging out', e)
    )
  }

  fixIssue(){
    this.firestore.collection('countries/GREECE/sections/MAIN BENEFIT TEST/fields').valueChanges()
      .subscribe(
        (doc)=>{
          console.log('FIX DOC', doc)
          // this.firestore.collection('countries/GREECE/sections/').add({MAIN BENEFIT TEST (MBT):{}})
          doc.forEach(
            (newfield)=>{
              this.firestore.collection('countries/GREECE/sections/MAIN BENEFIT TEST (MBT)/fields').add(newfield)
              .then(
                (res)=>{
                  console.log('RESULT ', res)
                }
              )
              .catch(
                (err)=>{
                  console.log('ERROR ', err)
                }
              )
            }
          )
          

        }
      )
  }

}
