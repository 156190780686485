import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ArticlesComponent } from './articles/articles.component';
import { NewsComponent } from './news/news.component';
import { ResourcesComponent } from './resources/resources.component';
import { HomepageComponent } from './homepage/homepage.component';
import { LogsComponent } from './logs/logs.component';
import { UsersPage } from './users/users.page';
import { AuthGuard } from './guards/auth.guard'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'articles',
    pathMatch: 'full', 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'articles', 
    component: ArticlesComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'homepage', 
    component: HomepageComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'news', 
    component: NewsComponent, 
    canActivate: [AuthGuard] 
  },
  { 
    path: 'logs', 
    component: LogsComponent, 
    canActivate: [AuthGuard] 
  },
  {
    path: 'users',
    component: UsersPage ,
    // loadChildren: () => import('./users/users.module').then( m => m.UsersPageModule), 
    canActivate: [AuthGuard]
  },
  { 
    path: 'resources', 
    component: ResourcesComponent, 
    canActivate: [AuthGuard] 
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
