import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as firebase from 'firebase/app';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user
  public userDetails

  constructor(private _firebaseAuth: AngularFireAuth, private router: Router, private fireStore: AngularFirestore) { 
    // this.getUserInfo()
    this._firebaseAuth.authState.subscribe(
      (res) => {
          if (res) {
            this.user = res;
            console.log('[Auth Service] logged user', res);
            this.fireStore.doc('users/' + this.user.uid).valueChanges().subscribe(
              (r)=>{
                this.userDetails = r
                console.log('[Auth Service] User details', this.userDetails);
              }
            )
          }
          else {
            this.user = null;
          }
        }
    )
    
  }

  getUserInfo(){
    this._firebaseAuth.authState.subscribe(
      (res) => {
          if (res) {
            this.user = res;
            console.log('[Auth Service] logged user', res);
            this.fireStore.doc('users/' + this.user.uid).valueChanges().subscribe(
              (r)=>{
                this.userDetails = r
                console.log('[Auth Service] User details', this.userDetails);
              }
            )
          }
          else {
            this.user = null;
          }
        }
    )
  }

  isLoggedIn() {
    // console.log('isLoggedIn', this.userDetails)
    if (this.userDetails && this.userDetails['userType'] === 'admin' ) {
        // console.log('YES isLoggedIn', this.userDetails)
        return true;
      } else {
        // console.log('NO isLoggedIn', this.userDetails)
        return false;
      }
    }

  logout() {
    this._firebaseAuth.signOut()
      .then((res) => this.router.navigate(['/login']));
  }

  signInRegular(email, password) {
      const credential = firebase.auth.EmailAuthProvider.credential( email, password );
  return this._firebaseAuth.signInWithEmailAndPassword(email, password)
  }

  signInWithTwitter() {
    return this._firebaseAuth.signInWithPopup(
      new firebase.auth.TwitterAuthProvider()
    )
  }

  signInWithFacebook() {
      return this._firebaseAuth.signInWithPopup(
        new firebase.auth.FacebookAuthProvider()
      )
    }
    
  signInWithGoogle() {
      return this._firebaseAuth.signInWithPopup(
        new firebase.auth.GoogleAuthProvider()
      )
    }



}

