import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { IonReorderGroup } from '@ionic/angular';
import {map, take} from 'rxjs/operators'
import { Pipe, PipeTransform } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { TextareaAutosizeDirective } from '../directives/textarea-autosize.directive';

import { AddFieldPage } from './add-field/add-field.page'
import { AddSectionPage } from './add-section/add-section.page'
import { AddCountryPage } from './add-country/add-country.page'
import { ShowFieldHistoryPage } from './show-field-history/show-field-history.page'
import { MainMenuComponent } from '../menus/main-menu/main-menu.component'
import { AuthService } from '../services/auth.service'

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
})
export class ArticlesComponent implements OnInit {
  @ViewChild(IonReorderGroup, {static: true}) reorderGroup: IonReorderGroup;

  countries
  sections
  fields
  selectedCountry
  selectedSection
  selectedField
  fieldCounter
  modifiedFields =[]
  canSaveFields = false
  currentUser
  currentUserAditionalData

  constructor(private firestore: AngularFirestore, private _firebaseAuth: AngularFireAuth, public modalController: ModalController, private authService: AuthService) {
    this.getUserInfo()
   }

  ngOnInit() {
    this.getCountries()
    this.getUserInfo()
  }

  getUserInfo(){
    this._firebaseAuth.authState.subscribe(
      (res) => {
          if (res) {
            this.currentUser = res;
            console.log('[Articles component] User details', this.currentUserAditionalData);
            this.firestore.doc('users/' + this.currentUser.uid).valueChanges().subscribe(
              (r)=>{
                this.currentUserAditionalData = r
              }
            )
          }
          else {
            this.currentUser = null;
          }
        }
    )
  }

  getCountries(){
    this.firestore.collection('countries').snapshotChanges()
    .subscribe(
      (res)=>{
        console.log('Countries ', res)
        res.sort((a, b) => a.payload.doc.id.localeCompare(b.payload.doc.id))

        this.countries = []
        res.forEach(
          r=>{
            this.countries.push(r.payload.doc.id)
          }
        )
      }
    )
  }

  selectCountry(countryId){
    this.selectedCountry = countryId
    this.selectedSection = null
    this.fields = null
    this.firestore.collection('countries/' + countryId + '/sections').snapshotChanges()
    .subscribe(
      (res)=>{
        this.sections = []
        res.forEach(
          r=>{
            this.sections.push(r.payload.doc.id)
          }
        )
      }
    )
  }

  selectSection(sectionId){
    this.canSaveFields = false
    this.selectedSection = sectionId
    this.firestore.collection('countries/' + this.selectedCountry + '/sections/' + sectionId + '/fields').snapshotChanges()
    .subscribe(
      (res)=>{
        res.sort(function (a, b) {
          if (a.payload.doc.data()['position'] > b.payload.doc.data()['position']) {
              return 1;
          }
          if (b.payload.doc.data()['position'] > a.payload.doc.data()['position']) {
              return -1;
          }
          return 0;
        })

        this.fields = []
        res.forEach(
          (r)=>{
            if(r.payload.doc.id === '_count'){
              this.fieldCounter = r.payload.doc.data()['no']
            }
            else{
              this.fields.push({key:r.payload.doc.id, data: r.payload.doc.data() })
            }
          }
        )
      }
    )
  }

  async addCountry(){
    const modal = await this.modalController.create({
      component: AddCountryPage
      });
      
    await modal.present();
    modal.onWillDismiss().then(
      (modalData) => {
        console.log('Modal Data country', modalData.data)
        if (modalData) {
          // trebuie sa avem macar un doc in colectie ca sa ii punem face sub-colectie
          this.firestore.collection('countries').doc(modalData.data)
          .set({dummy: 0})
        }
      }
    )
  }

  deleteCountry(country){
    if (confirm('Are you sure you want to delete this?') ){
      this.firestore.doc('countries/' + this.selectedCountry  )
        .delete()
    }
  }

  async addSection(){
    const modal = await this.modalController.create({
      component: AddSectionPage
      });
      
    await modal.present();
    modal.onWillDismiss().then(
      (modalData) => {
        console.log('Modal Data', modalData.data)
        if (modalData) {
          // trebuie sa avem macar un doc in colectie ca sa ii punem face sub-colectie
          this.firestore.collection('countries').doc(this.selectedCountry).collection('sections').doc(modalData.data)
          .set({_count: 0})

          this.firestore.collection('countries').doc(this.selectedCountry).collection('sections').doc(modalData.data).collection('fields').doc('_count')
            .set({no: 0})
            .then(
              (res)=> console.log('countries/' + this.selectedCountry + '/sections/' + modalData.data + '/fields/_count ' + 'Added section ', res)
            )
            .catch(
              (err)=> console.log('Error adding section', err)
            )
        }
      }
    )
  }

  deleteSection(section){

    if (confirm('Are you sure you want to delete this?') ){
      this.fieldCounter--
      this.firestore.doc('countries/' + this.selectedCountry + '/sections/' + section )
        .delete()
    }
  }

  async addField(){
    const modal = await this.modalController.create({
      component: AddFieldPage
      });
      
    await modal.present();
    modal.onWillDismiss().then(
      (modalData) => {
        // console.log('Modal Data', modalData.data)
        if (modalData) {
          this.fieldCounter++
          this.firestore.doc('countries/' + this.selectedCountry + '/sections/' + this.selectedSection + '/fields/_count')
            .update( { no: this.fieldCounter } )
            
          this.firestore.collection('countries/' + this.selectedCountry + '/sections/' + this.selectedSection + '/fields')
          .add(
            {
              name : modalData.data.name,
              content : modalData.data.content,
              position: this.fieldCounter
            }
          )
          .then(
            // Add to History
            (res)=>{
              this.firestore.doc('countries/' + this.selectedCountry + '/sections/' + this.selectedSection + '/fields/' + res.id).valueChanges()
              .subscribe(
                (r)=>{
                  let temp = {}
                  temp['action'] = 'added'
                  temp['dateTime'] = new Date()
                  temp['user'] = {
                    id: this.currentUser.uid,
                    displayName: this.currentUserAditionalData.name,
                    email: this.currentUser.email,
                    photoURL: this.currentUserAditionalData.photoURL
                  }
                  temp['field'] = r
                  this.firestore.doc('countries/' + this.selectedCountry + '/sections/' + this.selectedSection + '/fields/' + res.id ).collection('history')
                  .add(temp)
                  console.log('[Articles component] [addField] Field added ', temp)
                }
              )
              
            }
          )
        }
      }
    )
}

  deleteField(field){
    if (confirm('Are you sure you want to delete this?') ){
      this.fieldCounter--
      this.firestore.doc('countries/' + this.selectedCountry + '/sections/' + this.selectedSection + '/fields/_count')
        .update( { no: this.fieldCounter } )
        
      this.firestore.doc('countries/' + this.selectedCountry + '/sections/' + this.selectedSection + '/fields/' + field.key)
      .delete()
      .then(
        (res)=>{ console.log('Deleted field ', res)}
      )
    }
  }

  saveEnabled(index){
    this.canSaveFields = true
    if (!this.modifiedFields.includes(index)) {
      this.modifiedFields.push(index) 
    }
    else{
      console.log(index + ' has already been marked as modified')
    }
  }

  saveFields(){
    // update counts
    // this.firestore.doc('countries/' + this.selectedCountry + '/sections/' + this.selectedSection + '/fields/_count')
    //   .update( { no: this.fieldCounter } )
    // update all other fields
    this.modifiedFields.forEach((i) => {
      console.log('Modified fields', this.modifiedFields)
      let field = this.fields[i]
      console.log('Saving field', field)

      // Save New Data
      this.firestore.doc('countries/' + this.selectedCountry + '/sections/' + this.selectedSection + '/fields/' + field.key).update(field.data)
      .then(
        (res) => { this.canSaveFields = false}
      )

      // // save Data to History
      let temp = {}
              temp['action'] = 'edited'
              temp['dateTime'] = new Date()
              temp['user'] = {
                id: this.currentUser.uid,
                displayName: this.currentUser.displayName,
                email: this.currentUser.email,
                photoURL: this.currentUser.photoURL
              }
              temp['field'] = field.data
      this.firestore.doc('countries/' + this.selectedCountry + '/sections/' + this.selectedSection + '/fields/' + field.key ).collection('history')
      .add(temp)
      
    })
    alert('Data saved succesfully')
  }

  async showFieldHistory(fieldId){
    const modal = await this.modalController.create({
      component: ShowFieldHistoryPage,
      componentProps: {fieldPath: 'countries/' + this.selectedCountry + '/sections/' + this.selectedSection + '/fields/' + fieldId}
      });
      
    await modal.present();
    modal.onWillDismiss().then(
      (modalData) => {
        console.log('Modal Data country', modalData.data)
       
      }
    )
  }

  reOrderFields($event){
    console.log('Reorder', this.fields)
    console.log('ReOrder: ', $event)

  }

}
