import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { IonReorderGroup } from '@ionic/angular';
import {map, take} from 'rxjs/operators'
import { Pipe, PipeTransform } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { TextareaAutosizeDirective } from '../directives/textarea-autosize.directive';

import { AddNewsPage } from './add-news/add-news.page'
import { EditNewsPage } from './edit-news/edit-news.page'
import { MainMenuComponent } from '../menus/main-menu/main-menu.component'
import { AuthService } from '../services/auth.service'

@Component({
  selector: 'app-articles',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {

  news
  sections
  fields
  selectedCountry
  currentUser
  currentUserAditionalData

  constructor(private firestore: AngularFirestore,  private _firebaseAuth: AngularFireAuth, public modalController: ModalController, private authService: AuthService) {
    this.getUserInfo()
   }

   getUserInfo(){
    this._firebaseAuth.authState.subscribe(
      (res) => {
          if (res) {
            this.currentUser = res;
            console.log('[Auth Service] User details', res);
            this.firestore.doc('users/' + this.currentUser.uid).valueChanges().subscribe(
              (r)=>{
                this.currentUserAditionalData = r
              }
            )
          }
          else {
            this.currentUser = null;
          }
        }
    )
  }

  ngOnInit() {
    this.getNews() 
  }

  getNews(){
    this.firestore.collection('news').snapshotChanges()
    .subscribe(
      (res)=>{
        
        // res.sort((a, b) => a.payload.doc.data()['date_time'].localeCompare(b.payload.doc.data()['date_time']))

        this.news = []
        res.forEach(
          r=>{
            let art = {}
            art['info'] = r.payload.doc.data()
            art['id'] = r.payload.doc.id
            art['data'] = art['info']['date_time'].toDate()
            this.news.push(art)
          }
        )
        this.news
        .sort(function (a, b) {
          if (a['data'] > b['data']) {
              return -1;
          }
          if (b['data'] > a['data']) {
              return 1;
          }
          return 0;
        })
        console.log('News ', this.news)
      }
    )
  }


  async addArticle(){
    const modal = await this.modalController.create({
      component: AddNewsPage
      });
      
    await modal.present();
    modal.onWillDismiss().then(
      (modalData) => {
        if (modalData){
          let newArticle = modalData.data
          newArticle['user'] = {userId: this.currentUser.uid, displayName: this.currentUser.displayName , email: this.currentUser.email }
          newArticle['date_time'] = new Date()
          console.log('New article', newArticle)
          this.firestore.collection('news').add(modalData.data)
        }
      }
    )
  }

  async editNews(article){
   
    console.log('editUpdate ', article)
    const modalEdit = await this.modalController.create({
      component: EditNewsPage,
      componentProps: { article: article.info}
      });
      
    await modalEdit.present();
    modalEdit.onWillDismiss().then(
      (modalData) => {
        if (modalData){
          let newArticle = modalData.data
          console.log('newArticle', newArticle)
          if(newArticle['new_date']){
            newArticle['date_time'] = newArticle['new_date'].toDate()
            delete newArticle.new_date
            delete newArticle.date
          }
          console.log('New UPDATE info', newArticle)
          this.firestore.collection('news').doc(article.id).set(newArticle)
          .then(
            res => console.log('News Edited sucessuffly ', res)
          )
          .catch(
          err => console.log('News NOT Edited ', err)
          )
        }
      }
    )
  }

  deleteArticle(id){
    if (confirm('Are you sure you want to delete this?') ){
      this.firestore.doc('news/' + id  ).delete()
    }
  }



  reOrderArticles($event){
    console.log('Reorder', this.fields)
    console.log('ReOrder: ', $event)

  }

}
