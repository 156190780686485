import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { QuillModule } from 'ngx-quill'

@Component({
  selector: 'app-edit-resource',
  templateUrl: './edit-resource.page.html',
  styleUrls: ['./edit-resource.page.scss'],
})
export class EditResourcePage implements OnInit {
  resource
  datePickerConfig={
    
  }
  
  constructor(private modalController: ModalController, private firestore: AngularFirestore) { 
  }

  ngOnInit() {
    
  }


  async returnData(){
    this.modalController.dismiss(this.resource)
  }
}
